import { lazy } from 'react';

// Don't needs to be logged in to access these routes
// Don't needs have plan to access these routes
export const GET_PUBLIC_ROUTE_PATH = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PROFISSIONAL_LINK_CONFIRMATION: '/link-professional-approve',
  CUSTOMER_LINK_CONFIRMATION: '/link-costumer-approve',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
  TEST_NEW_CASHFLOW_TABLE: '/test-new-cashflow-table',
};

const fullAccessPolicies = {
  userTypesAllowed: ['*'],
  subscriptionStatusAllowed: ['*'],
  plansAllowed: ['*'],
};

const registerPolicies = {
  userTypesAllowed: ['*'],
  subscriptionStatusAllowed: ['OK'],
  plansAllowed: ['Profissionais', 'Gratuito'],
};

// Route Configurations
export const routeConfigs = {
  public: [
    { path: GET_PUBLIC_ROUTE_PATH.LANDING, exact: true, component: lazy(() => import('./containers/Pages/SignIn/SignIn')) },
    { path: GET_PUBLIC_ROUTE_PATH.SIGN_IN, component: lazy(() => import('./containers/Pages/SignIn/SignIn')) },
    { path: GET_PUBLIC_ROUTE_PATH.SIGN_UP, component: lazy(() => import('./containers/Pages/SignUp/SignUp')) },
    { path: GET_PUBLIC_ROUTE_PATH.FORGET_PASSWORD, component: lazy(() => import('./containers/Pages/ForgotPassword/ForgotPassword')) },
    {
      path: `${GET_PUBLIC_ROUTE_PATH.RESET_PASSWORD}/:id`,
      component: lazy(() => import('./containers/Pages/ResetPassword/ResetPassword')),
    },
    {
      path: `${GET_PUBLIC_ROUTE_PATH.PROFISSIONAL_LINK_CONFIRMATION}/:token`,
      component: lazy(() => import('./containers/Pages/linkApprove/Professional/LinkProfessional')),
    },
    {
      path: `${GET_PUBLIC_ROUTE_PATH.CUSTOMER_LINK_CONFIRMATION}/:token`,
      component: lazy(() => import('./containers/Pages/linkApprove/Customer/LinkCustomer')),
    },
  ],
  private: [
    {
      path: '',
      component: lazy(() => import('./containers/DashboardHomePage')),
      exact: true,
      policy: { ...fullAccessPolicies },
      sidebar: {
        icon: 'ion-home',
        label: 'sidebar.home',
      },
    },
    {
      path: 'admin',
      component: lazy(() => import('./containers/admin/AdminHomepage')),
      policy: {
        userTypesAllowed: ['SUB_ADMIN', 'ADMIN'],
        subscriptionStatusAllowed: ['*'], // allowed for all subscription status
        plansAllowed: ['*'], // allowed for all plans
      },
      sidebar: {
        icon: 'ion-speedometer',
        label: 'sidebar.dashboard.admin',
      },
    },
    {
      path: 'customer',
      // component: lazy(() => import('./containers/Customer/CustomerHomePage')),
      policy: {
        userTypesAllowed: ['ADMIN'],
        subscriptionStatusAllowed: ['*'], // allowed for all subscription status
        plansAllowed: ['*'], // allowed for all plans
      },
      sidebar: {
        icon: 'ion-speedometer',
        label: 'sidebar.dashboard.customer',
      },
    },
    {
      path: 'professional',
      // component: lazy(() => import('./containers/Professional/ProfessionalHomePage')),
      policy: {
        userTypesAllowed: ['ADMIN'],
        subscriptionStatusAllowed: ['*'], // allowed for all subscription status
        plansAllowed: ['*'], // allowed for all plans
      },
      sidebar: {
        icon: 'ion-speedometer',
        label: 'sidebar.dashboard.professional',
      },
    },
    {
      path: 'registers/supplier/:supplierId',
      component: lazy(() => import('./containers/pre-registers/Supplier/CreateEditSupplier')),
      policy: { ...registerPolicies },
      sidebar: undefined,
    },
    {
      path: 'registers/supplier',
      component: lazy(() => import('./containers/pre-registers/Supplier/SuppliersList')),
      policy: { ...registerPolicies },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.suppliers',
      },
    },
    {
      path: 'inspirations',
      component: lazy(() => import('./containers/Inspiration/Inspirations')),
      policy: {
        userTypesAllowed: ['*'], // allowed for all permissions
        subscriptionStatusAllowed: ['*'],
        plansAllowed: ['Profissionais', 'Programa', 'Gratuito'],
      },
      sidebar: {
        icon: 'ion-image',
        label: 'sidebar.inspirations',
      },
    },
    {
      path: 'registers/user/:customerId',
      component: lazy(() => import('./containers/pre-registers/Customer/CreateEditCustomer')),
      policy: { ...registerPolicies, userTypesAllowed: ['SUB_ADMIN', 'ADMIN'] },
      sidebar: undefined,
    },
    {
      path: 'registers/user',
      component: lazy(() => import('./containers/pre-registers/Customer/CustomersList')),
      policy: { ...registerPolicies, userTypesAllowed: ['SUB_ADMIN', 'ADMIN'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.users',
      },
    },
    {
      path: 'registers/customer/:customerId',
      component: lazy(() => import('./containers/pre-registers/Customer/CreateEditCustomer')),
      policy: { ...registerPolicies, userTypesAllowed: ['PROFESSIONAL'] },
      sidebar: undefined,
    },
    {
      path: 'registers/customer',
      component: lazy(() => import('./containers/pre-registers/Customer/CustomersList')),
      policy: { ...registerPolicies, userTypesAllowed: ['PROFESSIONAL'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.customers',
      },
    },
    {
      path: 'registers/professional/:customerId',
      component: lazy(() => import('./containers/pre-registers/Customer/CreateEditCustomer')),
      policy: { ...registerPolicies, userTypesAllowed: ['PROPRIETARY'] },
      sidebar: undefined,
    },
    {
      path: 'registers/professional',
      component: lazy(() => import('./containers/pre-registers/Customer/CustomersList')),
      policy: { ...registerPolicies, userTypesAllowed: ['PROPRIETARY'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.professionals',
      },
    },
    {
      path: 'registers/tags/:tagId',
      component: lazy(() => import('./containers/pre-registers/Tags/CreateEditTags')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: undefined,
    },
    {
      path: 'registers/tags',
      component: lazy(() => import('./containers/pre-registers/Tags/TagsList')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.tags',
      },
    },
    {
      path: 'registers/helps/:helpId',
      component: lazy(() => import('./containers/pre-registers/Help/CreateEditHelp.js')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: undefined,
    },
    {
      path: 'registers/helps',
      component: lazy(() => import('./containers/pre-registers/Help/HelpList.js')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.help',
      },
    },
    {
      path: 'registers/questions/:questionId',
      component: lazy(() => import('./containers/pre-registers/Questions/CreateEditQuestions')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: undefined,
    },
    {
      path: 'registers/questions',
      component: lazy(() => import('./containers/pre-registers/Questions/QuestionsList')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.art-questions',
      },
    },
    {
      path: 'registers/construction-phases/:constructionPhaseId',
      component: lazy(() => import('./containers/pre-registers/ConstructionPhases/CreateEditConstructionPhases')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
    },
    {
      path: 'registers/construction-phases',
      component: lazy(() => import('./containers/pre-registers/ConstructionPhases/ConstructionPhasesList')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.construction-phases',
      },
    },
    {
      path: 'registers/inspection-phases/:inspectionPhaseId',
      component: lazy(() => import('./containers/pre-registers/InspectionPhases/CreateEditInspectionPhases')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
    },
    {
      path: 'inspection/:id',
      component: lazy(() => import('./containers/InspectionPhases/InspectionPhase2')),
      policy: { ...fullAccessPolicies, plansAllowed: ['Profissionais'] },
    },
    {
      path: 'static-inspection/register',
      component: lazy(() => import('./containers/InspectionPhases/InspectionList')),
      policy: { ...fullAccessPolicies },
      sidebar: {
        icon: 'ion-search',
        label: 'sidebar.inspections',
      },
    },
    {
      path: 'registers/inspection-phases',
      component: lazy(() => import('./containers/pre-registers/InspectionPhases/InspectionPhasesList')),
      policy: { ...registerPolicies },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.inspection-phases',
      },
    },
    {
      path: 'static-inspection',
      component: lazy(() => import('./containers/InspectionPhases/InspectionFirstStep')),
      policy: { ...fullAccessPolicies },
      sidebar: {
        icon: 'ion-search',
        label: 'sidebar.inspections.static-inspection',
      },
    },
    {
      path: 'dynamic-inspection/:dynamicInspectionId',
      component: lazy(() => import('./containers/InspectionPhases/CreateEditDynamicInspection.js')),
      policy: { ...fullAccessPolicies },
    },
    {
      path: 'dynamic-inspection',
      component: lazy(() => import('./containers/InspectionPhases/DynamicInspectionList.js')),
      policy: { ...fullAccessPolicies },
      sidebar: {
        icon: 'ion-search',
        label: 'sidebar.inspections.dynamic-inspection',
      },
    },
    {
      path: 'registers/product/:productId',
      component: lazy(() => import('./containers/pre-registers/Products/CreateEditProduct')),
      policy: { ...registerPolicies },
    },
    {
      path: 'registers/service/:serviceId',
      component: lazy(() => import('./containers/pre-registers/Services/CreateEditService')),
      policy: { ...registerPolicies },
    },
    {
      path: 'registers/service',
      component: lazy(() => import('./containers/pre-registers/Services/ServiceList')),
      policy: { ...registerPolicies },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.services',
      },
    },
    {
      path: 'registers/product',
      component: lazy(() => import('./containers/pre-registers/Products/ProductList')),
      policy: { ...registerPolicies },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.products',
      },
    },
    {
      path: 'construction/:constructionId',
      component: lazy(() => import('./containers/ConstructionPhases/ConstructionPhasesDetail')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROPRIETARY', 'PROFESSIONAL'],
        subscriptionStatusAllowed: ['*'],
        plansAllowed: ['Profissionais', 'Programa', 'Gratuito'],
      },
    },
    {
      path: 'construction',
      component: lazy(() => import('./containers/ConstructionPhases/ConstructionPhases')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROPRIETARY', 'PROFESSIONAL'],
        subscriptionStatusAllowed: ['*'],
        plansAllowed: ['Profissionais', 'Programa', 'Gratuito'],
      },
      sidebar: {
        icon: 'ion-hammer',
        label: 'sidebar.phases-of-work',
      },
    },
    {
      path: 'financial-plans',
      component: lazy(() => import('./containers/Plans/PlansList')),
      policy: {
        userTypesAllowed: ['SUB_ADMIN', 'ADMIN'],
        subscriptionStatusAllowed: ['*'],
        plansAllowed: ['Profissionais'],
      },
      sidebar: {
        icon: 'ion-clipboard',
        label: 'sidebar.financial-plans',
      },
    },
    {
      path: 'quotation/:quotationId/details',
      component: lazy(() => import('./containers/Quotation/QuotationDetails')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROFESSIONAL', 'PROPRIETARY'],
        subscriptionStatusAllowed: ['OK'],
        plansAllowed: ['Profissionais', 'Programa', 'Gratuito'],
      },
    },
    {
      path: 'quotation/:quotationId',
      component: lazy(() => import('./containers/Quotation/CreateEditQuotation')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROFESSIONAL', 'PROPRIETARY'],
        subscriptionStatusAllowed: ['OK'],
        plansAllowed: ['Profissionais', 'Programa', 'Gratuito'],
      },
    },
    {
      path: 'quotation',
      component: lazy(() => import('./containers/Quotation/QuotationsList')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROFESSIONAL', 'PROPRIETARY'],
        subscriptionStatusAllowed: ['OK'],
        plansAllowed: ['Profissionais', 'Programa', 'Gratuito'],
      },
      sidebar: {
        icon: 'ion-cash',
        label: 'sidebar.budgets',
      },
    },
    {
      path: 'financial/financial/:payableId',
      component: lazy(() => import('./containers/Financial/Payables/CreateEditPayable')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROFESSIONAL', 'PROPRIETARY'],
        subscriptionStatusAllowed: ['OK'],
        plansAllowed: ['Profissionais'],
      },
    },
    {
      path: 'financial/financial',
      component: lazy(() => import('./containers/Financial/Payables/PayablesList.js')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROFESSIONAL', 'PROPRIETARY'],
        subscriptionStatusAllowed: ['OK'],
        plansAllowed: ['Profissionais'],
      },
      sidebar: {
        icon: 'ion-calculator',
        label: 'sidebar.financial.payables-receivables',
      },
    },
    {
      path: 'financial/cash-flow',
      component: lazy(() => import('./containers/Financial/CashFlow/CashFlowList.js')),
      policy: {
        userTypesAllowed: ['SUB_ADMIN', 'ADMIN', 'PROFESSIONAL', 'PROPRIETARY'],
        subscriptionStatusAllowed: ['OK'],
        plansAllowed: ['Profissionais'],
      },
      sidebar: {
        icon: 'ion-calculator',
        label: 'sidebar.financial.cash-flow',
      },
    },
    {
      path: 'edit-my-profile',
      component: lazy(() => import('./containers/Pages/Profile/EditProfile')),
      policy: {
        userTypesAllowed: ['*'],
        subscriptionStatusAllowed: ['*'],
        plansAllowed: ['*'],
      },
    },
    {
      path: 'registers/advertising/:advertisingId',
      component: lazy(() => import('./containers/pre-registers/Advertising/CreateEditAdvertising')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
    },
    {
      path: 'registers/advertising',
      component: lazy(() => import('./containers/pre-registers/Advertising/AdvertisingList')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.advertising',
      },
    },
    {
      path: 'registers/coupons/:couponId',
      component: lazy(() => import('./containers/pre-registers/Coupons/CreateEditCoupon')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
    },
    {
      path: 'registers/coupons',
      component: lazy(() => import('./containers/pre-registers/Coupons/CouponsList')),
      policy: { ...registerPolicies, plansAllowed: ['Profissionais'] },
      sidebar: {
        icon: 'ion-ios-paper',
        label: 'sidebar.registers.coupons',
      },
    },
    {
      path: 'available-coupons',
      component: lazy(() => import('./containers/AvailableCoupons/AvailableCoupons')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROFESSIONAL', 'PROPRIETARY'],
        subscriptionStatusAllowed: ['*'],
        plansAllowed: ['Profissionais', 'Gratuito'],
      },
      sidebar: {
        icon: 'ion-pricetag',
        label: 'sidebar.coupons',
      },
    },
    {
      path: 'artRrt/:artRrtId',
      component: lazy(() => import('./containers/ArtRrt/CreateEditArtRrt')),
      policy: {
        userTypesAllowed: ['PROPRIETARY'],
        subscriptionStatusAllowed: ['OK'],
        plansAllowed: ['*'],
      },
    },
    {
      path: 'artRrt',
      component: lazy(() => import('./containers/ArtRrt/ArtRrtList')),
      policy: {
        userTypesAllowed: ['PROPRIETARY'],
        subscriptionStatusAllowed: ['OK'],
        plansAllowed: ['*'],
      },
      sidebar: {
        icon: 'ion-document',
        label: 'sidebar.ART',
      },
    },
    {
      path: 'follow-up',
      component: lazy(() => import('./containers/Follow-up/FollowUp')),
      policy: {
        userTypesAllowed: ['ADMIN', 'PROPRIETARY', 'PROFESSIONAL'],
        subscriptionStatusAllowed: ['*'],
        plansAllowed: ['Profissionais', 'Programa'],
      },
      sidebar: {
        icon: 'ion-android-checkbox-outline',
        label: 'sidebar.following',
      },
    },
  ],
};

export class SidebarBuilder {
  user;

  routeConfigs = routeConfigs;

  constructor(user) {
    this.user = user;
  }

  build() {
    const sidebarItemsArray = [];

    for (const item of this.routeConfigs.private) {
      if (!item.sidebar) {
        continue;
      }

      const { sidebar, policy } = item;

      if (policy) {
        const { userTypesAllowed, subscriptionStatusAllowed, plansAllowed } = policy;

        if (!userTypesAllowed.includes('*') && !userTypesAllowed.includes(this.user.userType)) continue;
        if (!['ADMIN', 'SUB_ADMIN'].includes(this.user.userType)) {
          if (!subscriptionStatusAllowed.includes('*') && !subscriptionStatusAllowed.includes(this.user.subscriptionStatus)) continue;
          if (!plansAllowed.includes('*') && !plansAllowed.includes(this.user.plan.planType)) continue;
        }
      }

      const { icon, label } = sidebar;
      const splittedLabel = label.split('.');
      const [suffix, itemLabel, child] = splittedLabel;
      if (suffix !== 'sidebar') {
        continue;
      }

      if (!child) {
        sidebarItemsArray.push({
          key: item.path,
          label: label,
          leftIcon: icon,
        });
        continue;
      }

      const parentLabel = suffix + '.' + itemLabel;

      let parent = sidebarItemsArray.find((item) => item.label === parentLabel);

      if (!parent) {
        parent = {
          key: item.path,
          label: parentLabel,
          leftIcon: icon,
          children: [],
        };

        sidebarItemsArray.push(parent);
      }

      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push({
        key: item.path,
        label: label,
      });
    }

    return sidebarItemsArray;
  }
}
