import React, { Suspense, lazy, memo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import { ErrorBoundary } from './ErrorBoundary';
import Loader from './components/utility/loader';
import { GET_PUBLIC_ROUTE_PATH, routeConfigs } from './route.constants';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

export const PrivateRoute = memo(
  ({ path, exact, component: Component, policy = { userTypesAllowed: [], subscriptionStatusAllowed: [], plansAllowed: [] }, ...rest }) => {
    const { isLoading, isLoggedIn, user } = useSelector((state) => state.Auth);

    if (isLoading) return <Loader />;
    if (!isLoggedIn) return <Redirect to={GET_PUBLIC_ROUTE_PATH.SIGN_IN} />;

    const returnRoute = <Route {...rest} path={path} exact={exact} component={Component} />;

    if (user.userType === 'ADMIN' || user.userType === 'SUB_ADMIN') {
      return returnRoute;
    }

    const { userTypesAllowed, subscriptionStatusAllowed, plansAllowed } = policy;
    // Permission Check
    if (!subscriptionStatusAllowed.includes('*') && !userTypesAllowed.includes('*') && !userTypesAllowed.includes(user.userType)) {
      return (
        <div style={{ padding: 20, textAlign: 'center', color: 'red' }}>
          <p>Access Denied: You do not have the required permissions to view this page.</p>
          <Link to="/dashboard">Return to Dashboard</Link>
        </div>
      );
    }

    // Payment Check
    if (!subscriptionStatusAllowed.includes('*') && !subscriptionStatusAllowed.includes(user.subscriptionStatus)) {
      return (
        <>
          <div style={{ height: 48, backgroundColor: '#f17272', display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: 36 }}>
              Erro ao processar seu pagamento. Verifique as informações de pagamento do seu plano{' '}
              <Link to="/dashboard/edit-my-profile">aqui.</Link>
            </span>
          </div>
          <Route {...rest} path={path} exact={exact} component={Component} />
        </>
      );
    }

    // Plan Check
    if (!subscriptionStatusAllowed.includes('*') && !plansAllowed.includes('*') && !plansAllowed.includes(user.plan.planType)) {
      return (
        <div style={{ padding: 20, textAlign: 'center', color: 'red' }}>
          <p>Access Denied: Your current plan does not include access to this page.</p>
          <Link to="/dashboard">Return to Dashboard</Link>
        </div>
      );
    }

    return returnRoute;
  },
);

// Main Routes Component
export const Routes = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          {routeConfigs.public.map(({ path, exact, component: Component }) => (
            <Route key={path} path={path} exact={exact} component={Component} />
          ))}
          <PrivateRoute
            path="/dashboard"
            policy={{ userTypesAllowed: ['*'], subscriptionStatusAllowed: ['*'], plansAllowed: ['*'] }}
            component={Dashboard}
          />
          <Redirect to={GET_PUBLIC_ROUTE_PATH.SIGN_IN} />
        </Switch>
      </Router>
    </Suspense>
  </ErrorBoundary>
);
